import React from "react"
import './GuestbookComments.scss';

export default function GuestbookComments ( props ) {

  if (props.comments && props.comments.length > 0) {
    return (
      <>
        {    
          // Output all comments returned from Contentful API
          props.comments.map( (el, i) => {
            return (
              <div className="guestbook-comment" key={i}>
                <div className="comment-left">
                  <div className="colour" style={{backgroundColor: el.colour}}></div>
                  <div className="name-home">
                    <p>{el.name}</p>
                    <p>{el.whereIsHome}</p>
                  </div>
                </div>
                <div className="comment-right">
                  <p>{el.message}</p>
                </div>
              </div>
            )
          })
        }
      </>
    );
  } else if (props.comments && props.comments.length === 0) {
    return (
      <h1 style={{margin: 0}}>Be the first to join the conversation!</h1>
    )
  } else {
    return (
      <h1 style={{margin: 0}}>Loading...</h1>
    )
  }

}