import * as React from 'react';
import Header from '../components/Header/Header';
import * as contentful from 'contentful';
import * as contentfulManagement from 'contentful-management'
import Nav from "../components/Nav/Nav";
import GuestbookComments from '../components/GuestbookComments/GuestbookComments';
import { useStaticQuery } from 'gatsby';

const client = contentfulManagement.createClient({
  accessToken: 'CFPAT-7GX8OMtL_E0TaOyISVZ2AV-HHkynrfchCBdCk8n3kUg'
})

const commentsClient = contentful.createClient({
  space: 'qm9qo4up7qb7',
  environment: 'master', // defaults to 'master' if not set
  accessToken: 'Gmmmawgc1TnKfysvK50BPAF54x7eP_A4FEGzubA4cMo'
})

const isBrowser = typeof window !== "undefined";

export default class Guestbook extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isDesktop: true,
      formSubmitLoading: false,
      formSubmitted: false,
      comments: null
    }
  }

  setDisplaySettings = () => {
    if (isBrowser) {
      this.setState({
        isDesktop: window.innerWidth > 1200,
        colorInputValue: "#D8BFD8"
      })
    }
  }

  componentWillMount () {
    // query for comments
    commentsClient.getEntries({
      content_type: 'comments'
    })
    .then((data) => this.setState({comments: data.items.map(el => el.fields)}))
    .catch(console.error)
  }

  componentDidMount() {
    this.setDisplaySettings();
    window.addEventListener('resize', this.setDisplaySettings);
    this.setState({loading: false})
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setDisplaySettings);
  }

  handleGuestbookSubmit = (e) => {
    e.preventDefault();
    this.setState({formSubmitLoading: true})
    let formValues = [];
    const formData = new FormData(e.target)
    for (var value of formData.values()) {
      formValues.push(value);
    }

    // Create entry content
    let newCommentId = "id" + Math.random().toString(16).slice(2),
        postDate = new Date(),
        nameInputValue = formValues[0],
        homeInputValue = formValues[1],
        messageInputValue = formValues[2],
        colourInputValue = formValues[3];

    // Create entry
    client.getSpace('qm9qo4up7qb7')
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.createEntryWithId('comments', newCommentId, {
      fields: {
        postDate: {
          'en-US': postDate
        },
        name: {
          'en-US': nameInputValue
        },
        whereIsHome: {
          'en-US': homeInputValue
        },
        message: {
          'en-US': messageInputValue
        },
        colour: {
          'en-US': colourInputValue
        }
      }
    }))
    .then((entry) => entry.publish())
    .then((entry) => this.setState({
                                formSubmitLoading: false,
                                formSubmitted: true,
                                comments: [
                                            { 
                                              postDate: entry.fields.postDate["en-US"], 
                                              name: entry.fields.name["en-US"], 
                                              whereIsHome: entry.fields.whereIsHome["en-US"], 
                                              message: entry.fields.message["en-US"], 
                                              colour: entry.fields.colour["en-US"]
                                            },
                                            ...this.state.comments
                                            ]
                              }))
    .catch(console.error)
  }
             
  autosizeTextarea = e => {
    setTimeout(function(){
      e.target.style.cssText = 'height:auto; padding:0';
      e.target.style.cssText = 'height:' + e.target.scrollHeight + 'px';
    },0);
  }

  updateColorInput = e => {
    this.setState({
      colorInputValue: e.target.value
    })
  }

  renderForm = () => {
    // Submitted State
    if (this.state.formSubmitted && !this.state.formSubmitLoading) {
      return (
        <h1 className="form-success-message">Thank you for joining the conversation!</h1>
      )
    }
    // Loading State
    if (this.state.formSubmitLoading) {
      return (
        <>
          <div className="form-submit-loading">
            <h1>Submitting...</h1>
          </div>
          <h1 className="guestbook-title">Guestbook</h1>
          <form method="post" action="#" onSubmit={this.handleGuestbookSubmit}>
            <input required type="text" name="name" id="name" maxlength="256" disabled />
            <label htmlFor="name">Name</label>
            <input required type="text" name="whereIsHome" id="whereIsHome" maxlength="256" disabled />
            <label htmlFor="whereIsHome">Where is home?</label>
            <textarea required name="message" id="message" rows="1" maxlength="256" onKeyDown={this.autosizeTextarea} disabled/>
            <label htmlFor="message">Message</label>
            <div className="colour-picker">
              <label id="colour-label" htmlFor="colour">Pick a colour:</label>
              <input required type="color" name="colour" id="colour" disabled/>
            </div>
            <button type="submit"><div className="circle" disabled></div>submit</button>
          </form>
        </>
      )
    }
    // Normal State (form shows)
    if (!this.state.formSubmitLoading && !this.state.formSubmitted) {
      return (
        <>
          <h1 className="guestbook-title">Guestbook</h1>
          <form method="post" action="#" onSubmit={this.handleGuestbookSubmit}>
            <input required type="text" name="name" id="name" maxlength="256" />
            <label htmlFor="name">Name</label>
            <input required type="text" name="whereIsHome" id="whereIsHome" maxlength="256" />
            <label htmlFor="whereIsHome">Where is home?</label>
            <textarea required name="message" id="message" rows="1" maxlength="256" onKeyDown={this.autosizeTextarea} />
            <label htmlFor="message">Message</label>
            <div className="colour-picker">
              <label id="colour-label" htmlFor="colour">Pick a colour:</label>
              <input required type="color" name="colour" id="colour" value={this.state.colorInputValue} onChange={this.updateColorInput} />
            </div>
            <button type="submit"><div className="circle"></div>submit</button>
          </form>
        </>
      )
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <Header/>
          <div className="wrapper">
            <Nav />
          </div>
        </>
      )
    } else {
      if (this.state.isDesktop) {
        return (
          <>
          <Header/>
          <div className="wrapper">
          <Nav />
          <div className="guestbook-container splitpage-container">
            <div className="guestbook-left-side splitpage-left-side">
              {this.renderForm()}
            </div>
            <div className="guestbook-right-side splitpage-right-side">
              <GuestbookComments comments={this.state.comments} />
            </div>
          </div>
        </div>
        </>
        )
      } else {
        return (
          <>
          <Header/>
          <div className="wrapper">
          <Nav />
          <div className={this.state.guestbookMobileFormOpen ? "guestbook-mobile-form-container" : "guestbook-mobile-form-container hidden"}>
            <div className="guestbook-form-close" onClick={() => this.setState({ guestbookMobileFormOpen: false })}>
              <div></div>
              <div></div>
            </div>
            {this.renderForm()}
          </div>
          <div className="guestbook-container--mobile">
            <div className="guestbook-mobile-header">
              <h2>Guestbook</h2>
              <div className="guestbook-form-toggle" onClick={() => this.setState({ guestbookMobileFormOpen: true })}>
                <p>Comment</p>
              </div>
            </div>
            <div className="guestbook-comments">
              <GuestbookComments comments={this.state.comments} />
            </div>
          </div>
        </div>
        </>
        )
      }
    }
  }

}